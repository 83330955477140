import React, { useState } from "react"
import styled from "styled-components"
import axios from "axios"
import { navigate } from "gatsby"
import Page from "../components/page"
import Wrap from "../components/wrap"

const PageContact = () => {
  const [formState, setFormState] = useState({
    name: "",
    furigana: "",
    email: "",
    phone: "",
    age: "",
    message: "",
  })
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState("")

  const updateFormState = (field, value) => {
    const newFormState = { ...formState }
    newFormState[field] = value
    setFormState(newFormState)
  }

  const handleSubmit = e => {
    e.preventDefault()
    setSubmitting(true)

    const formData = {
      ...formState,
    }

    console.log(formData)

    axios({
      method: "post",
      url: "https://api.tryhard.me/tda/contact.php",
      // url: "http://localhost:8888/contact.php",
      data: { ...formData },
    })
      .then(function (response) {
        // handle success
        navigate("/thanks")
      })
      .catch(function (error) {
        // handle error
        console.log(error)
        setError("エラーが発生しました。")
      })
  }

  return (
    <Page title="お問い合わせ">
      <Wrap>
        <form onSubmit={handleSubmit}>
          <Form>
            <Field>
              <label htmlFor="name">
                <div className="label">
                  <span>お名前</span> <span className="required">必須</span>
                </div>
                <div className="input">
                  <input
                    name="name"
                    type="text"
                    id="name"
                    value={formState.name}
                    onChange={e => updateFormState("name", e.target.value)}
                    required
                  />
                </div>
              </label>
            </Field>
            <Field>
              <label htmlFor="furigana">
                <div className="label">
                  <span>フリガナ</span> <span className="required">必須</span>
                </div>
                <div className="input">
                  <input
                    name="furigana"
                    type="text"
                    id="furigana"
                    value={formState.furigana}
                    onChange={e => updateFormState("furigana", e.target.value)}
                    required
                  />
                </div>
              </label>
            </Field>
            <Field>
              <label htmlFor="email">
                <div className="label">
                  <span>メールアドレス</span>{" "}
                  <span className="required">必須</span>
                </div>
                <div className="input">
                  <input
                    name="email"
                    type="text"
                    id="email"
                    value={formState.email}
                    onChange={e => updateFormState("email", e.target.value)}
                    required
                  />
                </div>
              </label>
            </Field>
            <Field>
              <label htmlFor="phone">
                <div className="label">
                  <span>電話番号</span> <span className="required">必須</span>
                </div>
                <div className="input">
                  <input
                    name="phone"
                    type="text"
                    id="phone"
                    value={formState.phone}
                    onChange={e => updateFormState("phone", e.target.value)}
                    required
                  />
                </div>
              </label>
            </Field>
            <Field>
              <label htmlFor="age">
                <div className="label">
                  <span>年齢</span>
                </div>
                <div className="input">
                  <select
                    name="age"
                    id="age"
                    value={formState.age}
                    onChange={e => updateFormState("age", e.target.value)}
                  >
                    <option value="">年齢</option>
                    <option value="10代">10代</option>
                    <option value="20代">20代</option>
                    <option value="30代">30代</option>
                    <option value="40代">40代</option>
                    <option value="50代">50代</option>
                    <option value="60代">60代</option>
                    <option value="70代">70代</option>
                    <option value="80代">80代</option>
                  </select>
                </div>
              </label>
            </Field>
            <Field className="message">
              <label htmlFor="message">
                <div className="label">
                  <span>
                    ご不明点・ご質問などあれば
                    <br />
                    なんでもご記入ください
                  </span>
                </div>
                <div className="input">
                  <textarea
                    name="message"
                    type="text"
                    id="message"
                    value={formState.message}
                    onChange={e => updateFormState("message", e.target.value)}
                    required
                  />
                </div>
              </label>
            </Field>
            <div className="submit">
              <input
                type="submit"
                value="送信"
                disabled={
                  formState.name === "" ||
                  formState.furigana === "" ||
                  formState.email === "" ||
                  formState.phone === "" ||
                  formState.message === "" ||
                  submitting
                }
              />
            </div>
            {error && <div className="error">{error}</div>}
          </Form>
        </form>
      </Wrap>
    </Page>
  )
}

export default PageContact

const Form = styled.div`
  .submit {
    text-align: center;

    input {
      display: inline-block;
      width: 280px;
      margin: 0 0 3rem;
      padding: 0.5em;
      border: 0;
      border-radius: 0.25rem;
      background: ${({ theme }) => theme.yellow};
      color: #222;
      font-weight: 700;
      transition: background 0.25s, color 0.25s;

      &:hover {
        background: ${({ theme }) => theme.red};
        color: white;
      }

      &[disabled] {
        background: #d2d2d2;
        color: #222;

        &:hover {
          background: #d2d2d2;
          color: #222;
        }
      }
    }
  }
`

const Field = styled.div`
  margin: 2rem 0;
  font-size: 0.875em;

  label {
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    @media (max-width: 500px) {
      flex-direction: column;
    }
  }

  .label {
    width: 33%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media (max-width: 500px) {
      width: 100%;
      margin: 0 0 0.5rem;
    }
  }

  .input {
    width: 67%;

    @media (max-width: 500px) {
      width: 100%;
    }

    input {
      width: 100%;

      &:nth-child(2) {
        margin: 0.5rem 0 0 0;
      }
    }

    textarea {
      width: 100%;
      height: 200px;
    }

    select {
      width: 100%;
    }
  }

  .required {
    display: inline-block;
    margin: 0 0 0 0.5em;
    padding: 0.1em 0.5em;
    border-radius: 0.25em;
    background: red;
    color: white;
  }

  &.time {
    .label {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      div:not(:nth-child(1)) {
        margin: 0.5rem 0 0 0;
      }
    }
  }

  &.message {
    .label {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
`
